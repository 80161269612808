import type {
  ButtonsThemeSettings,
  FontStyle,
  ThemeInput
} from '@aurora/shared-generated/types/graphql-schema-types';
import { BackgroundExtension } from '@aurora/shared-generated/types/graphql-schema-types';
import type { ThemeResultFragment } from '@aurora/shared-generated/types/graphql-types';
import isFluid from '@aurora/shared-utils/helpers/styles/FluidHelper';
import Icons, { getIconPath } from '../../icons';
import { createCssVariablesForColor } from './ColorHelper';
import { createLightnessVariables } from './LightnessColorHelper';
import { getFontStyleForCss, toFontValue } from './FontHelper';
import isEqual from 'react-fast-compare';
import { removeTypename } from '../apollo/ApolloHelper';

/**
 * A thin wrapper around `createCssVariablesForColor` that returns `undefined` when the value passed
 * in is `null` or `undefined`.
 *
 * @param value the value
 * @param varPrefix the CSS var prefix.
 */
function safeCreateCssVariableColor(value: string | null | undefined, varPrefix: string) {
  return value != null && createCssVariablesForColor(value, varPrefix);
}

/**
 * A thin wrapper around `toFontValue` that returns `undefined` when the value passed
 * in is `null` or `undefined`.
 *
 * @param value the value
 */
function safeToFontValue(value: string | null | undefined): string | undefined {
  return value != null ? `${toFontValue(value)}, system-ui` : undefined;
}

/**
 * A thin wrapper around `getFontStyleForCss` that returns `undefined` when the value passed
 * in is `null` or `undefined`.
 *
 * @param value the value
 */
function safeGetFontStyleForCss(value: FontStyle | null | undefined): string | undefined {
  return value != null ? getFontStyleForCss(value) : undefined;
}

/**
 * A thin wrapper around `createLightnessVariables` that returns `undefined` when the value passed
 * in is `null` or `undefined`.
 *
 * @param value the value
 */
function safeCreateLightnessVariables(
  varPrefix: string,
  hostPrefix: string,
  value: number | null | undefined
) {
  return value != null ? createLightnessVariables(varPrefix, hostPrefix, value) : undefined;
}

/**
 * Reformats an enum value into a CSS prop format.
 *
 * @param value the value
 */
function reformatFromEnum(value: string | number | null | undefined): string | undefined {
  return value ? value.toString().replaceAll('_', '-').toLowerCase() : undefined;
}

type Buttons = Pick<
  ButtonsThemeSettings,
  | 'borderRadiusSm'
  | 'borderRadius'
  | 'borderRadiusLg'
  | 'paddingX'
  | 'paddingXLg'
  | 'paddingXHero'
  | 'paddingY'
  | 'paddingYLg'
  | 'paddingYHero'
  | 'fontStyle'
  | 'fontWeight'
  | 'textTransform'
  | 'disabledOpacity'
  | 'primaryTextColor'
  | 'primaryTextHoverColor'
  | 'primaryTextActiveColor'
  | 'primaryBgColor'
  | 'primaryBgHoverColor'
  | 'primaryBgActiveColor'
  | 'primaryBorder'
  | 'primaryBorderHover'
  | 'primaryBorderActive'
  | 'primaryBorderFocus'
  | 'primaryBoxShadowFocus'
  | 'secondaryTextColor'
  | 'secondaryTextHoverColor'
  | 'secondaryTextActiveColor'
  | 'secondaryBgColor'
  | 'secondaryBgHoverColor'
  | 'secondaryBgActiveColor'
  | 'secondaryBorder'
  | 'secondaryBorderHover'
  | 'secondaryBorderActive'
  | 'secondaryBorderFocus'
  | 'secondaryBoxShadowFocus'
  | 'tertiaryTextColor'
  | 'tertiaryTextHoverColor'
  | 'tertiaryTextActiveColor'
  | 'tertiaryBgColor'
  | 'tertiaryBgHoverColor'
  | 'tertiaryBgActiveColor'
  | 'tertiaryBorder'
  | 'tertiaryBorderHover'
  | 'tertiaryBorderActive'
  | 'tertiaryBorderFocus'
  | 'tertiaryBoxShadowFocus'
  | 'destructiveTextColor'
  | 'destructiveTextHoverColor'
  | 'destructiveTextActiveColor'
  | 'destructiveBgColor'
  | 'destructiveBgHoverColor'
  | 'destructiveBgActiveColor'
  | 'destructiveBorder'
  | 'destructiveBorderHover'
  | 'destructiveBorderActive'
  | 'destructiveBorderFocus'
  | 'destructiveBoxShadowFocus'
>;

function getButtonRules(buttons: Buttons): Record<string, string | undefined | null> {
  const {
    fontStyle,
    fontWeight,
    textTransform,
    disabledOpacity,
    borderRadiusSm,
    borderRadius,
    borderRadiusLg,
    paddingX: buttonPaddingX,
    paddingXLg: buttonPaddingXLg,
    paddingXHero,
    paddingY: buttonPaddingY,
    paddingYLg: buttonPaddingYLg,
    paddingYHero,
    primaryTextColor,
    primaryTextHoverColor,
    primaryTextActiveColor,
    primaryBgColor,
    primaryBgHoverColor,
    primaryBgActiveColor,
    primaryBorder,
    primaryBorderHover,
    primaryBorderActive,
    primaryBorderFocus,
    primaryBoxShadowFocus,
    secondaryTextColor,
    secondaryTextHoverColor,
    secondaryTextActiveColor,
    secondaryBgColor,
    secondaryBgHoverColor,
    secondaryBgActiveColor,
    secondaryBorder,
    secondaryBorderHover,
    secondaryBorderActive,
    secondaryBorderFocus,
    secondaryBoxShadowFocus,
    tertiaryTextColor,
    tertiaryTextHoverColor,
    tertiaryTextActiveColor,
    tertiaryBgColor,
    tertiaryBgHoverColor,
    tertiaryBgActiveColor,
    tertiaryBorder,
    tertiaryBorderHover,
    tertiaryBorderActive,
    tertiaryBorderFocus,
    tertiaryBoxShadowFocus,
    destructiveTextColor,
    destructiveTextHoverColor,
    destructiveTextActiveColor,
    destructiveBgColor,
    destructiveBgHoverColor,
    destructiveBgActiveColor,
    destructiveBorder,
    destructiveBorderHover,
    destructiveBorderActive,
    destructiveBorderFocus,
    destructiveBoxShadowFocus
  } = buttons;

  return {
    '--lia-btn-font-style': safeGetFontStyleForCss(fontStyle),
    '--lia-bs-btn-font-weight': fontWeight,
    '--lia-btn-text-transform': reformatFromEnum(textTransform),
    '--lia-bs-btn-disabled-opacity': disabledOpacity?.toString(),
    '--lia-bs-btn-border-radius-sm': borderRadiusSm,
    '--lia-bs-btn-border-radius': borderRadius,
    '--lia-bs-btn-border-radius-lg': borderRadiusLg,
    '--lia-bs-btn-padding-x': buttonPaddingX,
    '--lia-bs-btn-padding-x-lg': buttonPaddingXLg,
    '--lia-btn-padding-x-hero': paddingXHero,
    '--lia-bs-btn-padding-y': buttonPaddingY,
    '--lia-bs-btn-padding-y-lg': buttonPaddingYLg,
    '--lia-btn-padding-y-hero': paddingYHero,
    '--lia-btn-primary-text-color': primaryTextColor,
    '--lia-btn-primary-text-hover-color': primaryTextHoverColor,
    '--lia-btn-primary-text-active-color': primaryTextActiveColor,
    '--lia-btn-primary-bg-color': primaryBgColor,
    '--lia-btn-primary-bg-hover-color': primaryBgHoverColor,
    '--lia-btn-primary-bg-active-color': primaryBgActiveColor,
    '--lia-btn-primary-border': primaryBorder,
    '--lia-btn-primary-border-hover': primaryBorderHover,
    '--lia-btn-primary-border-active': primaryBorderActive,
    '--lia-btn-primary-border-focus': primaryBorderFocus,
    '--lia-btn-primary-box-shadow-focus': primaryBoxShadowFocus,
    '--lia-btn-secondary-text-color': secondaryTextColor,
    '--lia-btn-secondary-text-hover-color': secondaryTextHoverColor,
    '--lia-btn-secondary-text-active-color': secondaryTextActiveColor,
    '--lia-btn-secondary-bg-color': secondaryBgColor,
    '--lia-btn-secondary-bg-hover-color': secondaryBgHoverColor,
    '--lia-btn-secondary-bg-active-color': secondaryBgActiveColor,
    '--lia-btn-secondary-border': secondaryBorder,
    '--lia-btn-secondary-border-hover': secondaryBorderHover,
    '--lia-btn-secondary-border-active': secondaryBorderActive,
    '--lia-btn-secondary-border-focus': secondaryBorderFocus,
    '--lia-btn-secondary-box-shadow-focus': secondaryBoxShadowFocus,
    '--lia-btn-tertiary-text-color': tertiaryTextColor,
    '--lia-btn-tertiary-text-hover-color': tertiaryTextHoverColor,
    '--lia-btn-tertiary-text-active-color': tertiaryTextActiveColor,
    '--lia-btn-tertiary-bg-color': tertiaryBgColor,
    '--lia-btn-tertiary-bg-hover-color': tertiaryBgHoverColor,
    '--lia-btn-tertiary-bg-active-color': tertiaryBgActiveColor,
    '--lia-btn-tertiary-border': tertiaryBorder,
    '--lia-btn-tertiary-border-hover': tertiaryBorderHover,
    '--lia-btn-tertiary-border-active': tertiaryBorderActive,
    '--lia-btn-tertiary-border-focus': tertiaryBorderFocus,
    '--lia-btn-tertiary-box-shadow-focus': tertiaryBoxShadowFocus,
    '--lia-btn-destructive-text-color': destructiveTextColor,
    '--lia-btn-destructive-text-hover-color': destructiveTextHoverColor,
    '--lia-btn-destructive-text-active-color': destructiveTextActiveColor,
    '--lia-btn-destructive-bg-color': destructiveBgColor,
    '--lia-btn-destructive-bg-hover-color': destructiveBgHoverColor,
    '--lia-btn-destructive-bg-active-color': destructiveBgActiveColor,
    '--lia-btn-destructive-border': destructiveBorder,
    '--lia-btn-destructive-border-hover': destructiveBorderHover,
    '--lia-btn-destructive-border-active': destructiveBorderActive,
    '--lia-btn-destructive-border-focus': destructiveBorderFocus,
    '--lia-btn-destructive-box-shadow-focus': destructiveBoxShadowFocus
  };
}

/**
 * Get the CSS rules for a theme. The result will contain a map of CSS variables over
 * values, where the values will either be valid CSS values, references to other CSS variables, null,
 * or undefined.
 *
 * @param theme the theme to create the CSS rules for
 * @param includeSmartDefaults whether to include values that do not exist on the theme as well
 * as smart defaults for values that may be null in the theme.
 */
function getCssRulesForTheme(
  theme: ThemeResultFragment,
  includeSmartDefaults = true
): Record<string, string | undefined | null> {
  const {
    animation,
    avatar,
    basics,
    border,
    boxShadow,
    buttons,
    cards,
    chip,
    colorLightness,
    colors,
    coreTypes,
    divider,
    dropdown,
    emoji,
    email,
    heading,
    icons,
    imagePreview,
    input,
    link,
    listGroup,
    loading: loadingResult,
    modal,
    pager,
    panel,
    popover,
    prism,
    rte,
    tags,
    toasts,
    typography,
    unstyledListItem,
    yiq
  } = theme || {};

  const {
    maximumWidthOfPageContent,
    pageWidthStyle,
    gridGutterWidthMd,
    gridGutterWidthXs,
    oneColumnNarrowWidth
  } = basics ?? {};

  const isFluidBody = isFluid(maximumWidthOfPageContent ?? '');
  const bodyConstrained = pageWidthStyle === BackgroundExtension.WidthOfPageContent && !isFluidBody;

  const basicsRules = {
    '--lia-vars-loaded': includeSmartDefaults ? 'flex' : undefined,
    '--lia-body-margin': bodyConstrained ? '0 auto' : includeSmartDefaults ? '0' : null,
    '--lia-body-max-width': bodyConstrained
      ? maximumWidthOfPageContent
      : includeSmartDefaults
      ? '100%'
      : null,
    '--lia-container-max-width': !isFluidBody
      ? maximumWidthOfPageContent
      : includeSmartDefaults
      ? '100%'
      : null,
    '--lia-one-column-narrow-width': oneColumnNarrowWidth,
    '--lia-grid-gutter-width-md': gridGutterWidthMd,
    '--lia-grid-gutter-width-xs': gridGutterWidthXs
  };

  const {
    black,
    white,
    gray100,
    gray200,
    gray300,
    gray400,
    gray500,
    gray600,
    gray700,
    gray800,
    gray900,
    dark,
    light,
    primary,
    secondary,
    bodyBg,
    bodyText,
    info,
    success,
    warning,
    danger,
    alertSystem,
    textMuted,
    highlight,
    outline
  } = colors ?? {};

  const colorRules = {
    ...safeCreateCssVariableColor(black, '--lia-bs-black'),
    ...safeCreateCssVariableColor(white, '--lia-bs-white'),
    ...safeCreateCssVariableColor(gray100, '--lia-bs-gray-100'),
    ...safeCreateCssVariableColor(gray200, '--lia-bs-gray-200'),
    ...safeCreateCssVariableColor(gray300, '--lia-bs-gray-300'),
    ...safeCreateCssVariableColor(gray400, '--lia-bs-gray-400'),
    ...safeCreateCssVariableColor(gray500, '--lia-bs-gray-500'),
    ...safeCreateCssVariableColor(gray600, '--lia-bs-gray-600'),
    ...safeCreateCssVariableColor(gray700, '--lia-bs-gray-700'),
    ...safeCreateCssVariableColor(gray800, '--lia-bs-gray-800'),
    ...safeCreateCssVariableColor(gray900, '--lia-bs-gray-900'),
    ...safeCreateCssVariableColor(dark, '--lia-bs-dark'),
    ...safeCreateCssVariableColor(light, '--lia-bs-light'),
    ...safeCreateCssVariableColor(primary, '--lia-bs-primary'),
    ...safeCreateCssVariableColor(secondary, '--lia-bs-secondary'),
    ...safeCreateCssVariableColor(bodyBg, '--lia-bs-body-bg'),
    ...safeCreateCssVariableColor(bodyText, '--lia-bs-body-color'),
    ...safeCreateCssVariableColor(info, '--lia-bs-info'),
    ...safeCreateCssVariableColor(success, '--lia-bs-success'),
    ...safeCreateCssVariableColor(warning, '--lia-bs-warning'),
    ...safeCreateCssVariableColor(danger, '--lia-bs-danger'),
    ...safeCreateCssVariableColor(alertSystem, '--lia-alert-system-color'),
    ...safeCreateCssVariableColor(textMuted, '--lia-bs-text-muted'),
    ...safeCreateCssVariableColor(highlight, '--lia-highlight-color'),
    '--lia-outline-color': outline
  };

  const {
    fontSizeBase,
    fontSizeSm,
    fontSizeXxs,
    fontSizeXs,
    fontSizeLg,
    smallFontSize,
    fontSizeXl,
    fontFamilyBase,
    fontWeightBase,
    fontWeightLight,
    fontWeightNormal,
    fontWeightMd,
    fontWeightBold,
    fontStyleBase,
    lineHeightBase,
    letterSpacingSm,
    letterSpacingXs
  } = typography ?? {};

  const {
    h1FontSize,
    h2FontSize,
    h3FontSize,
    h4FontSize,
    h5FontSize,
    h6FontSize,
    fontFamily,
    lineHeight,
    fontWeight,
    fontStyle,
    subHeaderFontWeight,
    subHeaderFontSize,
    color: headingColor,
    h1LetterSpacing,
    h2LetterSpacing,
    h3LetterSpacing,
    h4LetterSpacing,
    h5LetterSpacing,
    h6LetterSpacing,
    subHeaderLetterSpacing,
    h1FontWeight,
    h2FontWeight,
    h3FontWeight,
    h4FontWeight,
    h5FontWeight,
    h6FontWeight
  } = heading ?? {};

  const typographyRules = {
    '--lia-bs-font-size-base': fontSizeBase,
    '--lia-bs-font-size-sm': fontSizeSm,
    '--lia-font-size-xxs': fontSizeXxs,
    '--lia-font-size-xs': fontSizeXs,
    '--lia-bs-font-size-lg': fontSizeLg,
    '--lia-bs-small-font-size': smallFontSize,
    '--lia-font-size-xl': fontSizeXl,
    '--lia-bs-font-family-base': safeToFontValue(fontFamilyBase),
    '--lia-bs-font-weight-base': fontWeightBase,
    '--lia-bs-font-weight-light': fontWeightLight,
    '--lia-bs-font-weight-normal': fontWeightNormal,
    '--lia-font-weight-md': fontWeightMd,
    '--lia-bs-font-weight-bold': fontWeightBold,
    '--lia-font-style-base': safeGetFontStyleForCss(fontStyleBase),
    '--lia-bs-line-height-base': lineHeightBase,
    '--lia-bs-h1-font-size': h1FontSize,
    '--lia-bs-h2-font-size': h2FontSize,
    '--lia-bs-h3-font-size': h3FontSize,
    '--lia-bs-h4-font-size': h4FontSize,
    '--lia-bs-h5-font-size': h5FontSize,
    '--lia-bs-h6-font-size': h6FontSize,
    '--lia-bs-headings-font-family': safeToFontValue(fontFamily),
    '--lia-bs-headings-line-height': lineHeight,
    '--lia-bs-headings-font-weight': fontWeight,
    '--lia-headings-font-style': safeGetFontStyleForCss(fontStyle),
    '--lia-subheader-font-size': subHeaderFontSize,
    '--lia-subheader-font-weight': subHeaderFontWeight,
    '--lia-bs-headings-color': headingColor,
    '--lia-h1-letter-spacing': h1LetterSpacing,
    '--lia-h2-letter-spacing': h2LetterSpacing,
    '--lia-h3-letter-spacing': h3LetterSpacing,
    '--lia-h4-letter-spacing': h4LetterSpacing,
    '--lia-h5-letter-spacing': h5LetterSpacing,
    '--lia-h6-letter-spacing': h6LetterSpacing,
    '--lia-letter-spacing-subheading': subHeaderLetterSpacing,
    '--lia-letter-spacing-sm': letterSpacingSm,
    '--lia-letter-spacing-xs': letterSpacingXs,
    '--lia-h1-font-weight': h1FontWeight,
    '--lia-h2-font-weight': h2FontWeight,
    '--lia-h3-font-weight': h3FontWeight,
    '--lia-h4-font-weight': h4FontWeight,
    '--lia-h5-font-weight': h5FontWeight,
    '--lia-h6-font-weight': h6FontWeight
  };

  const { color: borderColor, radiusSm, radius, radiusLg, radius50 } = border ?? {};

  const borderRules = {
    '--lia-bs-border-color': borderColor,
    '--lia-bs-border-radius-sm': radiusSm,
    '--lia-bs-border-radius': radius,
    '--lia-bs-border-radius-lg': radiusLg,
    '--lia-border-radius-50': radius50,
    '--lia-content-item-border': includeSmartDefaults ? 'none' : undefined
  };

  const { xs, sm, md, lg } = boxShadow ?? {};

  const boxShadowRules = {
    '--lia-box-shadow-xs': xs,
    '--lia-bs-box-shadow-sm': sm,
    '--lia-bs-box-shadow': md,
    '--lia-bs-box-shadow-lg': lg
  };

  const { color: linkColor, hoverColor: linkHoverColor, decoration, hoverDecoration } = link ?? {};

  const linkRules = {
    '--lia-bs-link-color': linkColor,
    '--lia-bs-link-hover-color': linkHoverColor,
    '--lia-bs-link-decoration': decoration,
    '--lia-bs-link-hover-decoration': hoverDecoration
  };

  const { fast, normal, slow, slowest, function: animationFunction } = animation ?? {};

  const animationRules = {
    '--lia-timing-fast': fast,
    '--lia-timing-normal': normal,
    '--lia-timing-slow': slow,
    '--lia-timing-slowest': slowest,
    '--lia-timing-function': animationFunction
  };

  const { borderRadius } = avatar ?? {};
  const avatarRules = {
    '--lia-avatar-border-radius': borderRadius
  };

  const {
    ideaMessageLinkFontStyle,
    qandaSolvedColor,
    grouphubColor,
    qandaFontStyle,
    ideaFontFamily,
    ideaMessageLinkFontWeight,
    contestFontFamily,
    ideaLineHeight,
    occasionFontWeight,
    communityColor,
    qandaMessageLinkColor,
    qandaMessageLinkDecoration,
    defaultMessageLinkColor,
    defaultMessageLinkDecoration,
    defaultMessageLinkFontStyle,
    defaultMessageLinkFontWeight,
    blogColor,
    forumColor,
    occasionMessageLinkFontWeight,
    tkbColor,
    defaultMessageFontWeight,
    contestMessageLinkFontStyle,
    qandaFontWeight,
    contestFontWeight,
    forumFontFamily,
    qandaLineHeight,
    tkbFontFamily,
    contestMessageLinkFontWeight,
    tkbMessageLinkColor,
    tkbMessageLinkDecoration,
    tkbLineHeight,
    blogLineHeight,
    contestMessageLinkColor,
    contestMessageLinkDecoration,
    forumFontWeight,
    forumMessageLinkFontWeight,
    ideaFontStyle,
    forumFontStyle,
    contestFontStyle,
    contestColor,
    ideaFontWeight,
    qandaFontFamily,
    qandaMessageLinkFontStyle,
    defaultMessageFontStyle,
    qandaMessageLinkFontWeight,
    qandaColor,
    forumLineHeight,
    tkbMessageLinkFontStyle,
    productColor,
    categoryColor,
    occasionLineHeight,
    forumMessageLinkFontStyle,
    ideaMessageLinkColor,
    ideaMessageLinkDecoration,
    ideaColor,
    occasionFontStyle,
    blogFontFamily,
    forumSolvedColor,
    occasionMessageLinkColor,
    occasionMessageLinkDecoration,
    occasionMessageLinkFontStyle,
    blogFontStyle,
    tkbMessageLinkFontWeight,
    contestLineHeight,
    blogMessageLinkFontWeight,
    tkbFontWeight,
    tkbFontStyle,
    blogFontWeight,
    blogMessageLinkColor,
    blogMessageLinkDecoration,
    occasionColor,
    occasionFontFamily,
    forumMessageLinkColor,
    forumMessageLinkDecoration,
    blogMessageLinkFontStyle
  } = coreTypes ?? {};

  const coreTypeRules = {
    '--lia-default-message-font-style': defaultMessageFontStyle,
    '--lia-default-message-font-weight': defaultMessageFontWeight,
    '--lia-default-message-link-color': defaultMessageLinkColor,
    '--lia-default-message-link-decoration': defaultMessageLinkDecoration,
    '--lia-default-message-link-font-weight': defaultMessageLinkFontWeight,
    '--lia-default-message-link-font-style': defaultMessageLinkFontStyle,
    ...safeCreateCssVariableColor(forumColor, '--lia-forum-color'),
    '--lia-forum-font-family': forumFontFamily,
    '--lia-forum-font-weight': forumFontWeight,
    '--lia-forum-line-height': forumLineHeight,
    '--lia-forum-font-style': forumFontStyle,
    '--lia-forum-message-link-color': forumMessageLinkColor,
    '--lia-forum-message-link-decoration': forumMessageLinkDecoration,
    '--lia-forum-message-link-font-style': forumMessageLinkFontStyle,
    '--lia-forum-message-link-font-weight': forumMessageLinkFontWeight,
    ...safeCreateCssVariableColor(forumSolvedColor, '--lia-forum-solved-color'),
    ...safeCreateCssVariableColor(blogColor, '--lia-blog-color'),
    '--lia-blog-font-family': blogFontFamily,
    '--lia-blog-font-weight': blogFontWeight,
    '--lia-blog-line-height': blogLineHeight,
    '--lia-blog-font-style': blogFontStyle,
    '--lia-blog-message-link-color': blogMessageLinkColor,
    '--lia-blog-message-link-decoration': blogMessageLinkDecoration,
    '--lia-blog-message-link-font-style': blogMessageLinkFontStyle,
    '--lia-blog-message-link-font-weight': blogMessageLinkFontWeight,
    ...safeCreateCssVariableColor(tkbColor, '--lia-tkb-color'),
    '--lia-tkb-font-family': tkbFontFamily,
    '--lia-tkb-font-weight': tkbFontWeight,
    '--lia-tkb-line-height': tkbLineHeight,
    '--lia-tkb-font-style': tkbFontStyle,
    '--lia-tkb-message-link-color': tkbMessageLinkColor,
    '--lia-tkb-message-link-decoration': tkbMessageLinkDecoration,
    '--lia-tkb-message-link-font-style': tkbMessageLinkFontStyle,
    '--lia-tkb-message-link-font-weight': tkbMessageLinkFontWeight,
    ...safeCreateCssVariableColor(qandaColor, '--lia-qanda-color'),
    '--lia-qanda-font-family': qandaFontFamily,
    '--lia-qanda-font-weight': qandaFontWeight,
    '--lia-qanda-line-height': qandaLineHeight,
    '--lia-qanda-font-style': qandaFontStyle,
    '--lia-qanda-message-link-color': qandaMessageLinkColor,
    '--lia-qanda-message-link-decoration': qandaMessageLinkDecoration,
    '--lia-qanda-message-link-font-style': qandaMessageLinkFontStyle,
    '--lia-qanda-message-link-font-weight': qandaMessageLinkFontWeight,
    '--lia-qanda-solved-color': qandaSolvedColor,
    ...safeCreateCssVariableColor(ideaColor, '--lia-idea-color'),
    '--lia-idea-font-family': ideaFontFamily,
    '--lia-idea-font-weight': ideaFontWeight,
    '--lia-idea-line-height': ideaLineHeight,
    '--lia-idea-font-style': ideaFontStyle,
    '--lia-idea-message-link-color': ideaMessageLinkColor,
    '--lia-idea-message-link-decoration': ideaMessageLinkDecoration,
    '--lia-idea-message-link-font-style': ideaMessageLinkFontStyle,
    '--lia-idea-message-link-font-weight': ideaMessageLinkFontWeight,
    ...safeCreateCssVariableColor(contestColor, '--lia-contest-color'),
    '--lia-contest-font-family': contestFontFamily,
    '--lia-contest-font-weight': contestFontWeight,
    '--lia-contest-line-height': contestLineHeight,
    '--lia-contest-font-style': contestFontStyle,
    '--lia-contest-message-link-color': contestMessageLinkColor,
    '--lia-contest-message-link-decoration': contestMessageLinkDecoration,
    '--lia-contest-message-link-font-style': contestMessageLinkFontStyle,
    '--lia-contest-message-link-font-weight': contestMessageLinkFontWeight,
    ...safeCreateCssVariableColor(occasionColor, '--lia-occasion-color'),
    '--lia-occasion-font-family': occasionFontFamily,
    '--lia-occasion-font-weight': occasionFontWeight,
    '--lia-occasion-line-height': occasionLineHeight,
    '--lia-occasion-font-style': occasionFontStyle,
    '--lia-occasion-message-link-color': occasionMessageLinkColor,
    '--lia-occasion-message-link-decoration': occasionMessageLinkDecoration,
    '--lia-occasion-message-link-font-style': occasionMessageLinkFontStyle,
    '--lia-occasion-message-link-font-weight': occasionMessageLinkFontWeight,
    ...safeCreateCssVariableColor(grouphubColor, '--lia-grouphub-color'),
    ...safeCreateCssVariableColor(categoryColor, '--lia-category-color'),
    ...safeCreateCssVariableColor(communityColor, '--lia-community-color'),
    ...safeCreateCssVariableColor(productColor, '--lia-product-color')
  };

  const {
    borderRadius: borderRadius1,
    boxShadow: boxShadow1,
    borderColor: borderColor1,
    bgColor
  } = panel ?? {};

  const panelRules = {
    '--lia-panel-bg-color': bgColor,
    '--lia-panel-border-radius': borderRadius1,
    '--lia-panel-border-color': borderColor1,
    '--lia-panel-box-shadow': boxShadow1
  };

  const { borderRadius: borderRadius2, boxShadow: boxShadow2, bgColor: bgColor1 } = cards ?? {};
  const cardRules = {
    '--lia-card-bg-color': bgColor1,
    '--lia-card-border-radius': borderRadius2,
    '--lia-card-box-shadow': boxShadow2
  };

  const {
    backgroundBg,
    contentBg,
    contentBoxShadowXs,
    contentTextColor,
    contentBoxShadow,
    smSize,
    mdSize,
    lgSize,
    backdropOpacity,
    headerFontWeight
  } = modal ?? {};

  const modalRules = {
    '--lia-bs-modal-content-color': contentTextColor,
    '--lia-bs-modal-content-bg': contentBg,
    '--lia-bs-modal-backdrop-bg': backgroundBg,
    '--lia-bs-modal-sm': smSize,
    '--lia-bs-modal-md': mdSize,
    '--lia-bs-modal-lg': lgSize,
    '--lia-bs-modal-backdrop-opacity': backdropOpacity?.toString(),
    '--lia-bs-modal-content-box-shadow-xs': contentBoxShadowXs,
    '--lia-bs-modal-content-box-shadow-xs-up': contentBoxShadow,
    '--lia-bs-modal-header-font-weight': headerFontWeight
  };

  const {
    size10,
    size12,
    size14,
    size16,
    size20,
    size24,
    size30,
    size40,
    size50,
    size60,
    size80,
    size120,
    size160
  } = icons ?? {};

  const iconSizeRules = {
    '--lia-icon-size-10': size10,
    '--lia-icon-size-12': size12,
    '--lia-icon-size-14': size14,
    '--lia-icon-size-16': size16,
    '--lia-icon-size-20': size20,
    '--lia-icon-size-24': size24,
    '--lia-icon-size-30': size30,
    '--lia-icon-size-40': size40,
    '--lia-icon-size-50': size50,
    '--lia-icon-size-60': size60,
    '--lia-icon-size-80': size80,
    '--lia-icon-size-120': size120,
    '--lia-icon-size-160': size160
  };

  const {
    focusBoxShadow,
    borderRadius: borderRadiusInput,
    disabledColor,
    labelMarginBottom,
    borderColor: borderColorInput,
    borderRadiusSm,
    checkLabelMarginBottom,
    checkboxBorderRadius,
    focusBorderColor,
    btnFontSize,
    borderRadiusLg,
    formTextMarginTop,
    textAreaBorderRadius,
    activeFillColor
  } = input ?? {};

  const inputRules = {
    '--lia-bs-input-border-color': borderColorInput,
    '--lia-bs-input-disabled-color': disabledColor,
    '--lia-bs-input-focus-border-color': focusBorderColor,
    '--lia-bs-label-margin-bottom': labelMarginBottom,
    '--lia-bs-input-btn-font-size': btnFontSize,
    '--lia-bs-input-focus-box-shadow': focusBoxShadow,
    '--lia-check-label-margin-bottom': checkLabelMarginBottom,
    '--lia-checkbox-border-radius': checkboxBorderRadius,
    '--lia-bs-input-border-radius-sm': borderRadiusSm,
    '--lia-bs-input-border-radius': borderRadiusInput,
    '--lia-bs-input-border-radius-lg': borderRadiusLg,
    '--lia-bs-form-text-margin-top': formTextMarginTop,
    '--lia-textarea-border-radius': textAreaBorderRadius,
    '--lia-input-active-fill-color': activeFillColor
  };

  const {
    maxWidth,
    arrowHeight,
    borderRadius: borderRadius4,
    boxShadow: boxShadow3,
    borderColor: borderColor4,
    arrowWidth,
    minWidth,
    headerBg
  } = popover ?? {};

  const popoverRules = {
    '--lia-bs-popover-arrow-height': arrowHeight,
    '--lia-bs-popover-arrow-width': arrowWidth,
    '--lia-bs-popover-max-width': maxWidth,
    '--lia-bs-popover-min-width': minWidth,
    '--lia-bs-popover-header-bg': headerBg,
    '--lia-bs-popover-border-color': borderColor4,
    '--lia-bs-popover-border-radius': borderRadius4,
    '--lia-bs-popover-box-shadow': boxShadow3
  };

  const {
    itemPaddingX,
    headerColor,
    borderRadius: borderRadius5,
    fontSize,
    dividerBg,
    borderColor: borderColor5,
    itemPaddingY
  } = dropdown ?? {};

  const dropdownRules = {
    '--lia-bs-dropdown-font-size': fontSize,
    '--lia-bs-dropdown-border-color': borderColor5,
    '--lia-bs-dropdown-border-radius': borderRadius5,
    '--lia-bs-dropdown-divider-bg': dividerBg,
    '--lia-bs-dropdown-item-padding-y': itemPaddingY,
    '--lia-bs-dropdown-item-padding-x': itemPaddingX,
    '--lia-bs-dropdown-header-color': headerColor
  };

  const {
    itemPaddingX: itemPaddingX1,
    borderColor: borderColor6,
    itemPaddingY: itemPaddingY1
  } = listGroup ?? {};

  const listGroupRules = {
    '--lia-bs-list-group-item-padding-y': itemPaddingY1,
    '--lia-bs-list-group-item-padding-x': itemPaddingX1,
    '--lia-bs-list-group-border-color': borderColor6
  };

  const { marginBottomSm, marginBottomXxl, marginBottomLg, marginBottomXl, marginBottomMd } =
    unstyledListItem ?? {};

  const unlistedListRules = {
    '--lia-list-li-mb-sm': marginBottomSm,
    '--lia-list-li-mb-md': marginBottomMd,
    '--lia-list-li-mb-lg': marginBottomLg,
    '--lia-list-li-mb-xl': marginBottomXl,
    '--lia-list-li-mb-xxl': marginBottomXxl
  };

  const { titleColor, controlColor, controlBgColor, bgColor: bgColor4 } = imagePreview ?? {};

  const imagePreviewRules = {
    '--lia-img-preview-content-bg-color': bgColor4,
    '--lia-img-preview-title-color': titleColor,
    '--lia-img-preview-control-color': controlColor,
    '--lia-img-preview-control-bg-color': controlBgColor
  };

  const { barDarkColor, barLightColor, dotLightColor, dotDarkColor } = loadingResult ?? {};
  const loadingRules = {
    '--lia-load-dot-dark-color': dotDarkColor,
    '--lia-load-dot-light-color': dotLightColor,
    '--lia-load-bar-dark-color': barDarkColor,
    '--lia-load-bar-light-color': barLightColor
  };

  const { textFontWeight, textColor, textFontSize } = pager ?? {};
  const pagerRules = {
    '--lia-load-text-color': textColor,
    '--lia-load-text-font-weight': textFontWeight,
    '--lia-load-text-font-size': textFontSize
  };

  const {
    bgColorActive,
    marginRight,
    borderRadius: borderRadius6,
    bgColor: bgColor5,
    marginLeft,
    size
  } = divider ?? {};

  const dividerRules = {
    '--lia-divider-size': size,
    '--lia-divider-ml': marginLeft,
    '--lia-divider-mr': marginRight,
    '--lia-divider-border-radius': borderRadius6,
    '--lia-divider-bg-color': bgColor5,
    '--lia-divider-bg-color-active': bgColorActive
  };

  const { maxWidth: maxWidth1, height } = chip ?? {};
  const chipRules = {
    '--lia-chip-max-width': maxWidth1,
    '--lia-chip-height': height
  };

  const { dark: dark1, light: light1 } = yiq ?? {};
  const yiqRules = {
    ...safeCreateCssVariableColor(light1, '--lia-yiq-light'),
    ...safeCreateCssVariableColor(dark1, '--lia-yiq-dark')
  };

  const {
    customColor16,
    boxShadow: boxShadow4,
    customColor14,
    customColor13,
    customColor12,
    customColor11,
    customColor10,
    customColor9,
    customColor8,
    customColor7,
    customColor6,
    customColor5,
    customColor4,
    customColor3,
    customColor2,
    customColor1,
    customColor21,
    customColor20,
    customColor19,
    customColor22,
    customColor15,
    bgColor: bgColor6,
    borderRadius: borderRadius7,
    customColor18,
    customColor17,
    diffAddedColor,
    diffChangedColor,
    diffNoneColor,
    diffRemovedColor,
    defaultMessageHeaderMarginTop,
    defaultMessageHeaderMarginBottom,
    defaultMessageItemMarginTop,
    defaultMessageItemMarginBottom,
    specialMessageHeaderMarginTop,
    specialMessageHeaderMarginBottom,
    specialMessageItemMarginTop,
    specialMessageItemMarginBottom
  } = rte ?? {};

  const rteRules = {
    '--lia-rte-bg-color': bgColor6,
    '--lia-rte-border-radius': borderRadius7,
    '--lia-rte-box-shadow': boxShadow4,
    '--lia-rte-custom-color-1': customColor1,
    '--lia-rte-custom-color-2': customColor2,
    '--lia-rte-custom-color-3': customColor3,
    '--lia-rte-custom-color-4': customColor4,
    '--lia-rte-custom-color-5': customColor5,
    '--lia-rte-custom-color-6': customColor6,
    '--lia-rte-custom-color-7': customColor7,
    '--lia-rte-custom-color-8': customColor8,
    '--lia-rte-custom-color-9': customColor9,
    '--lia-rte-custom-color-10': customColor10,
    '--lia-rte-custom-color-11': customColor11,
    '--lia-rte-custom-color-12': customColor12,
    '--lia-rte-custom-color-13': customColor13,
    '--lia-rte-custom-color-14': customColor14,
    '--lia-rte-custom-color-15': customColor15,
    '--lia-rte-custom-color-16': customColor16,
    '--lia-rte-custom-color-17': customColor17,
    '--lia-rte-custom-color-18': customColor18,
    '--lia-rte-custom-color-19': customColor19,
    '--lia-rte-custom-color-20': customColor20,
    '--lia-rte-custom-color-21': customColor21,
    '--lia-rte-custom-color-22': customColor22,
    '--lia-rte-default-message-header-mt': defaultMessageHeaderMarginTop,
    '--lia-rte-default-message-header-mb': defaultMessageHeaderMarginBottom,
    '--lia-rte-default-message-item-mt': defaultMessageItemMarginTop,
    '--lia-rte-default-message-item-mb': defaultMessageItemMarginBottom,
    '--lia-rte-diff-added-color': diffAddedColor,
    '--lia-rte-diff-removed-color': diffRemovedColor,
    '--lia-rte-diff-none-color': diffNoneColor,
    '--lia-rte-diff-changed-color': diffChangedColor,
    '--lia-rte-special-message-header-mt': specialMessageHeaderMarginTop,
    '--lia-rte-special-message-header-mb': specialMessageHeaderMarginBottom,
    '--lia-rte-special-message-item-mt': specialMessageItemMarginTop,
    '--lia-rte-special-message-item-mb': specialMessageItemMarginBottom
  };

  const {
    color: colorPrism,
    bgColor: bgColorPrism,
    fontFamily: fontFamilyPrism,
    fontSize: fontSizePrism,
    fontWeightBold: fontWeightBoldPrism,
    fontStyleItalic,
    tabSize,
    highlightColor,
    commentColor,
    punctuationColor,
    namespaceOpacity,
    propColor,
    selectorColor,
    operatorColor,
    operatorBgColor,
    keywordColor,
    functionColor,
    variableColor
  } = prism ?? {};

  const prismRules = {
    '--lia-prism-color': colorPrism,
    '--lia-prism-bg-color': bgColorPrism,
    '--lia-prism-font-family': fontFamilyPrism,
    '--lia-prism-font-size': fontSizePrism,
    '--lia-prism-font-weight-bold': fontWeightBoldPrism,
    '--lia-prism-font-style-italic': fontStyleItalic,
    '--lia-prism-tab-size': tabSize?.toString(),
    '--lia-prism-highlight-color': highlightColor,
    '--lia-prism-comment-color': commentColor,
    '--lia-prism-punctuation-color': punctuationColor,
    '--lia-prism-namespace-opacity': namespaceOpacity,
    '--lia-prism-prop-color': propColor,
    '--lia-prism-selector-color': selectorColor,
    '--lia-prism-operator-color': operatorColor,
    '--lia-prism-operator-bg-color': operatorBgColor,
    '--lia-prism-keyword-color': keywordColor,
    '--lia-prism-function-color': functionColor,
    '--lia-prism-variable-color': variableColor
  };

  const {
    bgColor: tagBgColor,
    bgHoverColor: tagBgHoverColor,
    borderRadius: tagBorderRadius,
    color: tagColor,
    hoverColor: tagHoverColor,
    fontWeight: tagFontWeight,
    fontSize: tagFontSize,
    textTransform: tagTextTransform,
    letterSpacing: tagLetterSpacing
  } = tags ?? {};

  const tagRules = {
    '--lia-tag-bg-color': tagBgColor,
    '--lia-tag-bg-hover-color': tagBgHoverColor,
    '--lia-tag-border-radius': tagBorderRadius,
    '--lia-tag-color': tagColor,
    '--lia-tag-hover-color': tagHoverColor,
    '--lia-tag-font-weight': tagFontWeight,
    '--lia-tag-font-size': tagFontSize,
    '--lia-tag-text-transform': reformatFromEnum(tagTextTransform),
    '--lia-tag-letter-spacing': tagLetterSpacing
  };

  const { borderRadius: borderRadiusToast, paddingX: paddingXToast } = toasts ?? {};

  const toastRules = {
    '--lia-bs-toast-border-radius': borderRadiusToast,
    '--lia-bs-toast-padding-x': paddingXToast
  };

  const {
    link: {
      color: linkColorEmail,
      hoverColor: linkHoverColorEmail,
      decoration: linkDecorationEmail,
      hoverDecoration: linkHoverDecorationEmail
    } = link ?? {},
    border: { color: borderColorEmail } = border ?? {},
    buttons: {
      borderRadiusLg: primaryBorderRadiusEmail,
      paddingXLg: primaryPaddingXEmail,
      paddingYLg: primaryPaddingYEmail,
      fontWeight: primaryFontWeightEmail,
      primaryTextColor: primaryButtonTextColorEmail,
      primaryTextHoverColor: primaryButtonTextHoverColorEmail,
      primaryBgColor: primaryButtonBgColorEmail,
      primaryBgHoverColor: primaryButtonBgHoverColorEmail,
      primaryBorder: primaryButtonBorderEmail,
      primaryBorderHover: primaryButtonBorderHoverEmail
    } = buttons ?? {},
    panel: { borderColor: panelBorderColorEmail, borderRadius: panelBorderRadiusEmail } = panel ??
      {}
  } = email ?? {};

  const emailRules = {
    '--lia-email-link-color': linkColorEmail,
    '--lia-email-link-hover-color': linkHoverColorEmail,
    '--lia-email-link-decoration': linkDecorationEmail,
    '--lia-email-link-hover-decoration': linkHoverDecorationEmail,
    '--lia-email-border-color': borderColorEmail,
    '--lia-email-btn-border-radius-lg': primaryBorderRadiusEmail,
    '--lia-email-btn-padding-x-lg': primaryPaddingXEmail,
    '--lia-email-btn-padding-y-lg': primaryPaddingYEmail,
    '--lia-email-btn-font-weight': primaryFontWeightEmail,
    '--lia-email-btn-primary-text-color': primaryButtonTextColorEmail,
    '--lia-email-btn-primary-text-hover-color': primaryButtonTextHoverColorEmail,
    '--lia-email-btn-primary-bg-color': primaryButtonBgColorEmail,
    '--lia-email-btn-primary-bg-hover-color': primaryButtonBgHoverColorEmail,
    '--lia-email-btn-primary-border': primaryButtonBorderEmail,
    '--lia-email-btn-primary-border-hover': primaryButtonBorderHoverEmail,
    '--lia-email-panel-border-color': panelBorderColorEmail,
    '--lia-email-panel-border-radius': panelBorderRadiusEmail
  };

  const {
    skinToneLight,
    skinToneDefault,
    skinToneMedium,
    skinToneMediumDark,
    skinToneDark,
    skinToneMediumLight
  } = emoji ?? {};

  const emojiRules = {
    '--lia-emoji-skin-tone-default': skinToneDefault,
    '--lia-emoji-skin-tone-light': skinToneLight,
    '--lia-emoji-skin-tone-medium-light': skinToneMediumLight,
    '--lia-emoji-skin-tone-medium': skinToneMedium,
    '--lia-emoji-skin-tone-medium-dark': skinToneMediumDark,
    '--lia-emoji-skin-tone-dark': skinToneDark
  };

  function toUrlString(icon: Icons): string {
    const url = getIconPath(icon);

    return `url(${url})`;
  }

  /** These exist so that css can refer to the icon enum indirectly */
  const iconsRules =
    theme && includeSmartDefaults
      ? {
          '--lia-checkmark-input-icon': toUrlString(Icons.CheckmarkInputIcon),
          '--lia-chevron-down-icon': toUrlString(Icons.ChevronDownIcon),
          '--lia-close-icon': toUrlString(Icons.CloseIcon),
          '--lia-edit-icon': toUrlString(Icons.EditIcon),
          '--lia-select-arrow-icon': toUrlString(Icons.SelectArrowIcon),
          '--lia-video-play-active-icon': toUrlString(Icons.VideoPlayActiveIcon),
          '--lia-video-play-disabled-icon': toUrlString(Icons.VideoPlayDisabledIcon),
          '--lia-user-icon': toUrlString(Icons.UserIcon),
          '--lia-user-mention-icon': toUrlString(Icons.MentionIcon),
          '--lia-content-blog-icon': toUrlString(Icons.ContentBlogIcon),
          '--lia-content-knowledge-icon': toUrlString(Icons.ContentKnowledgeIcon),
          '--lia-content-category-icon': toUrlString(Icons.ContentCategoryIcon),
          '--lia-content-contest-icon': toUrlString(Icons.ContentContestIcon),
          '--lia-content-discussion-icon': toUrlString(Icons.ContentDiscussionIcon),
          '--lia-content-discussion-question-icon': toUrlString(
            Icons.ContentDiscussionQuestionIcon
          ),
          '--lia-content-occasion-icon': toUrlString(Icons.ContentOccasionIcon),
          '--lia-content-group-icon': toUrlString(Icons.ContentGroupIcon),
          '--lia-content-idea-icon': toUrlString(Icons.ContentIdeaIcon),
          '--lia-external-link-icon': toUrlString(Icons.ExternalLinkIcon),
          '--lia-anchor-icon': toUrlString(Icons.AnchorIcon)
        }
      : {};

  const {
    primaryDark,
    primaryLight,
    primaryLighter,
    primaryLightest,
    infoDark,
    infoLight,
    infoLighter,
    infoLightest,
    successDark,
    successLight,
    successLighter,
    successLightest,
    warningDark,
    warningLight,
    warningLighter,
    warningLightest,
    dangerDark,
    dangerLight,
    dangerLighter,
    dangerLightest
  } = colorLightness ?? {};

  const colorLightnessRules = {
    ...safeCreateLightnessVariables('--lia-primary-dark', '--lia-bs-primary', primaryDark),
    ...safeCreateLightnessVariables('--lia-primary-light', '--lia-bs-primary', primaryLight),
    ...safeCreateLightnessVariables('--lia-primary-lighter', '--lia-bs-primary', primaryLighter),
    ...safeCreateLightnessVariables('--lia-primary-lightest', '--lia-bs-primary', primaryLightest),
    ...safeCreateLightnessVariables('--lia-info-dark', '--lia-bs-info', infoDark),
    ...safeCreateLightnessVariables('--lia-info-light', '--lia-bs-info', infoLight),
    ...safeCreateLightnessVariables('--lia-info-lighter', '--lia-bs-info', infoLighter),
    ...safeCreateLightnessVariables('--lia-info-lightest', '--lia-bs-info', infoLightest),
    ...safeCreateLightnessVariables('--lia-success-dark', '--lia-bs-success', successDark),
    ...safeCreateLightnessVariables('--lia-success-light', '--lia-bs-success', successLight),
    ...safeCreateLightnessVariables('--lia-success-lighter', '--lia-bs-success', successLighter),
    ...safeCreateLightnessVariables('--lia-success-lightest', '--lia-bs-success', successLightest),
    ...safeCreateLightnessVariables('--lia-warning-dark', '--lia-bs-warning', warningDark),
    ...safeCreateLightnessVariables('--lia-warning-light', '--lia-bs-warning', warningLight),
    ...safeCreateLightnessVariables('--lia-warning-lighter', '--lia-bs-warning', warningLighter),
    ...safeCreateLightnessVariables('--lia-warning-lightest', '--lia-bs-warning', warningLightest),
    ...safeCreateLightnessVariables('--lia-danger-dark', '--lia-bs-danger', dangerDark),
    ...safeCreateLightnessVariables('--lia-danger-light', '--lia-bs-danger', dangerLight),
    ...safeCreateLightnessVariables('--lia-danger-lighter', '--lia-bs-danger', dangerLighter),
    ...safeCreateLightnessVariables('--lia-danger-lightest', '--lia-bs-danger', dangerLightest)
  };

  const result = {
    ...basicsRules,
    ...colorRules,
    ...typographyRules,
    ...(buttons && getButtonRules(buttons)),
    ...borderRules,
    ...boxShadowRules,
    ...linkRules,
    ...animationRules,
    ...avatarRules,
    ...coreTypeRules,
    ...panelRules,
    ...cardRules,
    ...modalRules,
    ...iconSizeRules,
    ...inputRules,
    ...popoverRules,
    ...dropdownRules,
    ...listGroupRules,
    ...unlistedListRules,
    ...imagePreviewRules,
    ...loadingRules,
    ...pagerRules,
    ...dividerRules,
    ...chipRules,
    ...yiqRules,
    ...rteRules,
    ...prismRules,
    ...tagRules,
    ...toastRules,
    ...emojiRules,
    ...emailRules,
    ...iconsRules,
    ...colorLightnessRules
  };

  // Remove key/values where the values are null or undefined
  Object.keys(result).forEach(key => {
    if (result[key] == null) {
      delete result[key];
    }
  });

  return result;
}

/**
 * Get a CSS style selector and rule, as a string, from the current theme
 *
 * @param theme the theme
 * @param applyToRoot whether to apply the theme to the `:root`
 * @param applyToSection whether to apply the theme to a named section
 * @param sectionId the section id when applying the theme to a section, if none specified it will use the theme id
 */
function getStyleContentFromTheme(
  theme: ThemeResultFragment,
  applyToRoot = true,
  applyToSection = false,
  sectionId: string = null
): string {
  if (!applyToRoot && !applyToSection) {
    return '';
  }

  const rules = getCssRulesForTheme(theme);
  const { id } = theme;
  const areaSelectors: string[] = [];

  if (applyToRoot) {
    areaSelectors.push(':root');
  }
  if (applyToSection) {
    areaSelectors.push(`[data-lia-styles="${sectionId ?? id}"]`);
  }

  const ruleContent = Object.entries(rules)
    .map(([key, value]) => `${key}: ${value};`)
    .join('');

  return `${areaSelectors.join(',')} { ${ruleContent} }`;
}

/**
 * Creates an object that contains only the differences between the two themes provides. There
 * will always be an id of the theme diff output that matches the theme of the first theme provided.
 *
 * @param themeA the first theme
 * @param themeB the second theme
 */
function getThemeDiff(
  themeA: ThemeResultFragment | ThemeInput,
  themeB: ThemeResultFragment | ThemeInput
): ThemeResultFragment {
  const themeDiff: ThemeResultFragment = {
    id: themeA.id
  };

  function safeAddItem(topLevelKey, nestedKey, value) {
    if (themeDiff[topLevelKey] === undefined) {
      themeDiff[topLevelKey] = {};
    }
    themeDiff[topLevelKey][nestedKey] = value;
  }

  // Iterate over top level properties of the theme
  Object.keys(themeA).forEach(topLevelKey => {
    const themeProperty = themeA[topLevelKey];

    // We only want to diff the top level properties whose value is an object
    if (typeof themeProperty === 'object') {
      // Check if each property on the top level objects is the same, and only
      // capture ones that are different
      Object.entries(themeA[topLevelKey]).forEach(([nestedKey, valueA]) => {
        const valueB = themeB?.[topLevelKey]?.[nestedKey];

        // If the values are arrays, then we need to check if the arrays are equal
        if (Array.isArray(valueA) && Array.isArray(valueB)) {
          if (
            valueA.length !== valueB.length ||
            !valueA.every((value, index) => value === valueB[index])
          ) {
            safeAddItem(topLevelKey, nestedKey, valueA);
          }
        } else if (typeof valueA === 'object' && typeof valueB === 'object') {
          if (!isEqual(removeTypename(valueA), removeTypename(valueB))) {
            safeAddItem(topLevelKey, nestedKey, valueA);
          }
        } else if (valueA !== valueB) {
          safeAddItem(topLevelKey, nestedKey, valueA);
        }
      });
    }
  });

  return themeDiff;
}

export { getCssRulesForTheme, getStyleContentFromTheme, getThemeDiff };
