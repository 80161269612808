export enum NewPasswordFieldCustomValidationError {
  HAS_DIGIT = 'hasDigit',
  LOWER = 'lower',
  MINIMUM_LENGTH = 'minimumLength',
  NO_PART_OF_USERNAME = 'noPartOfUsername',
  REPEATED_SEQUENCE = 'repeatedSequence',
  UPPER = 'upper',
  UPPER_AND_LOWER = 'upperAndLower',
  HAS_SPECIAL_CHAR = 'hasSpecialChar'
}
