import type {
  CommunityLanguagePropertiesQuery,
  CommunityLanguagePropertiesQueryVariables
} from '@aurora/shared-generated/types/graphql-types';
import { AppType } from '@aurora/shared-types/app';
import { EndUserPages } from '@aurora/shared-types/pages/enums';
import IntlHelper from '@aurora/shared-utils/helpers/i18n/IntlHelper';
import { DEFAULT_LANGUAGE } from '@aurora/shared-utils/helpers/i18n/defaultLanguage';
import { getLog } from '@aurora/shared-utils/log';
import { canUseDOM } from 'exenv';
import type { PropsWithChildren } from 'react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { IntlProvider } from 'react-intl';
import communityLanguagePropertiesQuery from '../../community/CommunityLanguageProperties.query.graphql';
import useLocalizedCategoriesFeatureEnabled from '../../community/useLocalizedCategoriesFeatureEnabled';
import useQueryWithTracing from '../../useQueryWithTracing';
import AppTypeContext from '../AppTypeContext';
import IntlWrapperContext from './IntlWrapperContext';
import useEndUserRoutes from '../../../routes/useEndUserRoutes';

const log = getLog(module);

interface Props extends PropsWithChildren {
  /**
   * The current locale
   */
  locale: string;
  /**
   * The accept locale from request
   */
  acceptLanguage: Intl.Locale[];
  /**
   * The current timeZone
   */
  timeZone: string;
}

/**
 * A wrapper around IntlProvider to be able to update locale without page
 * reload (ex. on login, logout settings preference change).
 *
 * @author Agustin Sosa
 */
const IntlWrapperContextProvider: React.FC<Props> = ({
  locale,
  acceptLanguage,
  timeZone,
  children
}) => {
  const [localeState, setLocaleState] = useState<string>(locale);
  const [acceptLanguageState, setAcceptLanguageState] = useState<Intl.Locale[]>(acceptLanguage);
  const [messagesState, setMessagesState] = useState<Record<never, string>>({});
  const [timeZoneState, setTimeZoneState] = useState<string>(timeZone);
  const currentApp = useContext(AppTypeContext);

  const { enabled: isLocalizedCategoriesFeatureEnabled } =
    useLocalizedCategoriesFeatureEnabled(module);
  const { router } = useEndUserRoutes();
  const currentPage = router.getCurrentPageName();
  const isLocalizeableApp =
    currentApp === AppType.END_USER &&
    currentPage !== EndUserPages.PageEditorPage &&
    currentPage !== EndUserPages.ThemeEditorPage;

  const { data: communityLanguageData, error: communityLanguageError } = useQueryWithTracing<
    CommunityLanguagePropertiesQuery,
    CommunityLanguagePropertiesQueryVariables
  >(module, communityLanguagePropertiesQuery, {
    skip: !canUseDOM
  });

  /**
   * Updates locale and clears intl messages object.
   *
   * @param newLocale the updated locale
   */
  const updateLocale = useCallback((newLocale: string): void => {
    setLocaleState(newLocale);
    setMessagesState({});
  }, []);

  /**
   * Updates the timezone .
   *
   * @param newTimezone the updated timeZone
   */
  const updateTimezone = useCallback((newTimezone: string): void => {
    setTimeZoneState(newTimezone);
  }, []);

  useEffect(() => {
    if (isLocalizedCategoriesFeatureEnabled) {
      const languagePickerCookie = IntlHelper.getLocaleFromLocalizedCategoryLocaleCookie();

      if (isLocalizeableApp && languagePickerCookie) {
        updateLocale(languagePickerCookie);
      }

      if (canUseDOM) {
        const finalLanguages = [...navigator?.languages].map(language => new Intl.Locale(language));
        if (!isEqual(finalLanguages, acceptLanguageState)) {
          setAcceptLanguageState(finalLanguages);
        }
      }
    }
  }, [isLocalizedCategoriesFeatureEnabled, isLocalizeableApp, updateLocale, acceptLanguageState]);

  /**
   * Update the language to default language when current page is Page Editor Page
   * Placed this on a separate useEffect because the {localeState} dependency on the useEffect above is making this fix
   * to provoke a regression issue: LIA-93861
   */
  useEffect(() => {
    if (!isLocalizeableApp) {
      updateLocale(DEFAULT_LANGUAGE);
    }
  }, [isLocalizeableApp, updateLocale, currentPage]);

  /**
   * Update the timezone based on the user preferences.
   */
  useEffect(() => {
    const timeZonePreferencesCookie = IntlHelper.getTimezoneFromCookie();
    if (isLocalizeableApp && timeZonePreferencesCookie) {
      updateTimezone(timeZonePreferencesCookie);
    }
  }, [isLocalizeableApp, timeZoneState, updateTimezone]);

  if (communityLanguageError) {
    log.error(communityLanguageError, 'error fetching language data');
  }

  return (
    <IntlWrapperContext.Provider
      value={{
        locale: localeState,
        acceptLanguage: acceptLanguageState,
        setLocale: updateLocale,
        setTimezone: updateTimezone,
        allowedLanguages: communityLanguageData?.allowedLanguages ?? [DEFAULT_LANGUAGE]
      }}
    >
      <IntlProvider
        key={`${localeState}${timeZone ?? ''}`}
        locale={localeState}
        timeZone={timeZoneState}
        defaultLocale={DEFAULT_LANGUAGE}
        messages={messagesState}
      >
        {children}
      </IntlProvider>
    </IntlWrapperContext.Provider>
  );
};

export default IntlWrapperContextProvider;
