const extensionToMimeType: Record<string, string> = {
  '*.png': 'image/png',
  '*.jpg': 'image/jpeg',
  '*.jpeg': 'image/jpeg',
  '*.gif': 'image/gif',
  '*.svg': 'image/svg+xml',
  '*.tiff': 'image/tiff',
  '*.bmp': 'image/bmp',
  '*.psd': 'image/vnd.adobe.photoshop'
};

const extensionToCustomMimeType: Record<string, string> = {
  story: 'application/story',
  swu: 'application/swu',
  har: 'application/x-har+json',
  cer: 'application/pkix-cert',
  evy: 'application/envoy',
  fif: 'application/fractals',
  f3d: 'application/fusion',
  hta: 'application/hta',
  acx: 'application/internet-property-stream',
  vi: 'application/octet-stream',
  enz: 'application/octet-stream',
  axs: 'application/olescript',
  pbix: 'application/pbix',
  pbit: 'application/pbit',
  icb: 'image/x-tga',
  enf: 'application/x-endnote-filter',
  ens: 'application/x-endnote-style',
  ins: 'application/x-internet-signup',
  isp: 'application/x-internet-signup',
  jmpaddin: 'application/x-jmp-addin',
  jmp: 'application/x-jmp-data',
  jrn: 'application/x-jmp-journal',
  jmpprj: 'application/x-jmp-project',
  jrp: 'application/x-jmp-report',
  jsl: 'application/x-jmp-script',
  jmpflow: 'application/x-jmp-flow',
  jmpdoe: 'application/x-jmp-doe',
  jmpapp: 'text/plain',
  ass: 'text/plain',
  iks: 'text/plain',
  scn: 'text/plain',
  snp: 'text/plain',
  dyn: 'text/plain',
  lin: 'text/plain',
  cps: 'text/plain',
  jmpappsource: 'text/plain',
  wdlt: 'text/plain',
  jmpquery: 'text/plain',
  undp: 'text/plain',
  orionreport: 'text/plain',
  fpg: 'text/plain',
  lis: 'text/plain',
  aut: 'text/plain',
  ioc: 'text/plain',
  xmcd: 'application/x-mathcad-xmcd',
  xmcdz: 'application/x-mathcad-xmcdz',
  mcdx: 'application/x-mathcad-mcdx',
  pma: 'application/x-perfmon',
  pmc: 'application/x-perfmon',
  pmr: 'application/x-perfmon',
  pmw: 'application/x-perfmon',
  pko: 'application/ynd.ms-pkipko',
  yxzp: 'application/zip',
  vip: 'application/zip',
  msapp: 'application/zip',
  spk: 'application/zip',
  ach: 'application/zip',
  nfp: 'application/zip',
  nwp: 'application/zip',
  f3z: 'application/zip',
  rerx: 'application/zip',
  jmpprjarchive: 'application/zip',
  swrd: 'application/zip',
  psap: 'application/zip',
  qvf: 'audio/mpeg',
  adt: 'audio/vnd.dlna.adts',
  dts: 'audio/vnd.dts',
  lpi: 'application/gzip',
  sst: 'application/vnd.ms-pkicertstore',
  par: 'application/x-tika-msoffice',
  rvt: 'application/x-tika-msoffice',
  rfa: 'application/x-tika-msoffice',
  ipt: 'application/x-tika-msoffice',
  dws: 'image/vnd.dwg',
  dwt: 'image/vnd.dwg',
  arw: 'image/x-raw-sony',
  woff: 'font/woff',
  woff2: 'font/woff2',
  art: 'image/jpeg',
  nws: 'message/rfc822',
  uls: 'text/iuls',
  ttl: 'text/turtle',
  sct: 'text/scriptlet',
  htt: 'text/webviewhtml',
  htc: 'text/x-component',
  mpa: 'video/mpeg',
  mpv2: 'video/mpeg',
  lsf: 'video/x-la-asf',
  lsx: 'video/x-la-asf',
  asr: 'video/x-ms-asf',
  flr: 'x-world/x-vrml',
  wrz: 'x-world/x-vrml',
  xaf: 'x-world/x-vrml',
  xof: 'x-world/x-vrml',
  yxmd: 'application/xml',
  gvi: 'application/xml',
  yxmc: 'application/xml',
  ism: 'application/xml',
  iap_xml: 'application/xml',
  nwf: 'application/xml',
  issuite: 'application/xml',
  ndi: 'application/xml',
  rpx: 'application/xml',
  tgml: 'application/xml',
  oft: 'application/vnd.ms-outlook',
  cpt: 'application/mac-compactpro',
  dxp: 'application/vnd.spotfire.dxp',
  ifb: 'text/calendar'
};

export function getMimeTypeForExtension(extension: string) {
  return extensionToMimeType[extension.toLowerCase()];
}

export function getCustomMimeTypeForExtension(extension: string) {
  return extensionToCustomMimeType[extension.toLowerCase()];
}
