import { useContext, useEffect } from 'react';
import RedirectContext from './context/RedirectContext/RedirectContext';
import { canUseDOM } from 'exenv';
import type { RedirectStatusCode } from './useIsomorphicRedirect';

/**
 * Hook that supports redirecting either on the server-side during initial rendering of the
 * page, or on the client-side when navigating pages from the browser.
 *
 * @param shouldRedirect whether to redirect
 * @param url the external URL to reroute to
 * @param statusCode the status code used when redirecting
 * @param noCache whether the url data should be stored in browser cache
 * @author Adam Ayres
 */
export default function useIsomorphicRedirectByUrl(
  shouldRedirect: boolean,
  url: string,
  statusCode?: RedirectStatusCode,
  noCache?: boolean
): void {
  const { setRedirect } = useContext(RedirectContext);

  /**
   * For client-side renderings, we use the effect hook to redirect the page.
   */
  useEffect(() => {
    if (shouldRedirect && canUseDOM && url) {
      window.location.href = url;
    }
  }, [url, shouldRedirect]);

  /**
   * On the server-side, we use a callback on the `RedirectContext` to set the
   * redirect info. This is then consumed in the `getInitialProps` in `_app` which
   * supports redirecting for server-side renderings.
   */
  if (shouldRedirect && !canUseDOM && url) {
    setRedirect(url, statusCode, noCache);
  }
}
